@font-face {
    font-family: 'Azo';
    src: url('../fonts/AzoSans-Regular.woff2') format('woff2'),
         url('../fonts/AzoSans-Regular.woff') format('woff');
    font-weight: 400;
}

@font-face {
    font-family: 'Azo';
    src: url('../fonts/AzoSans-Medium.woff2') format('woff2'),
         url('../fonts/AzoSans-Medium.woff') format('woff');
    font-weight: 500;
}

@font-face {
    font-family: 'Azo';
    src: url('../fonts/AzoSans-Bold.woff2') format('woff2'),
         url('../fonts/AzoSans-Bold.woff') format('woff');
    font-weight: 700;
}

@font-face {
    font-family: 'Azo';
    src: url('../fonts/AzoSans-Black.woff2') format('woff2'),
         url('../fonts/AzoSans-Black.woff') format('woff');
    font-weight: 900;
}

@font-face {
    font-family: 'AzoUber';
    src: url('../fonts/AzoSansUber-Regular.woff2') format('woff2'),
         url('../fonts/AzoSansUber-Regular.woff') format('woff');
    font-weight: 400;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Regular.woff2') format('woff2'),
         url('../fonts/Lato-Regular.woff') format('woff');
    font-weight: 400;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Bold.woff2') format('woff2'),
         url('../fonts/Lato-Bold.woff') format('woff');
    font-weight: 700;
}