.footer {
 background: $gradient-bg-footer;
 &-content {
  align-items: center;
  justify-content: space-between;
 }
 
 &-copy, &-links {
  margin-top: 8rem;
 }

 &-copy {
  p {
   color: $lato-color;
  }
 }

 &-flex-mobile {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 62%;
 }

 &-logo {
  transform: translateX(50%);
  picture {
   max-width: 168px;
  }
 }

 &-links {
  gap: 3rem;
 }
}
@media all and (max-width: 900px) {
 .footer {
  &-logo {
   transform: none;
  }
 }
}

@media all and (max-width: 830px) {
 .footer {
  &-content {
   flex-direction: column-reverse;
  }
  &-copy, &-links {
   margin: 1rem 0;
  }
  &-flex-mobile {
   flex-direction: column;
  }
 }
}