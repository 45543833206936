*,
*::before,
*::after {
 box-sizing: border-box;
 outline: none;
}

html {
 font-size: $font-size-general;
 scroll-behavior: smooth;
}

body {
 font-family: $font-family-general;
 margin: 0;
 padding: 0;
 font-weight: 400;
 line-height: $line-height;
 font-size: $font-size-general;
}

picture {
 display: block;
 position: relative;
 height: auto;
 width: 100%;
 img {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
 }
}

.flex {
 display: flex;
}

.wrapper {
 max-width: calc(1370px + 3rem);
 margin: 0 auto;
 padding: 0 1.5rem;
 width: 100%;
}

.font {
 &-uber {
  font-family: $font-family-uber;
 }
 &-deco {
  font-family: $font-deco;
 }
 &-medium {
  font-weight: 500;
 }
 &-bold {
  font-weight: 700;
 }
 &-black {
  font-weight: 900;
 }
}


.title {
 &-big {
  font-size: 9.7rem;
 }
 &-medium {
  font-size: 4rem;
 }
 &-small {
  font-size: 3.8rem;
 }
}

.text {
 &-big {
  font-size: 2.2rem;
 }
 &-medium {
  font-size: 1.8rem;
 }
 &-up {
  text-transform: uppercase;
 }
}

.primary {
 color: $main-color;
}

.white {
 color: $light-color;
}

.dark {
 color: $dark-color;
}

.pink {
 color: $form-button-color;
}


@media all and (max-width: 1024px) {
 .title {
  &-big {
   font-size: 6.5rem;
  }
  &-medium {
   font-size: 3rem;
  }
  &-small {
   font-size: 2.3rem;
  }
 }
}

@media all and (max-width: 500px) {
 .title {
  &-big {
   font-size: 4.7rem;
  }
  &-medium {
   font-size: 3.5rem;
  }
 }
 .text {
  &-big {
   font-size: 1.8rem;
  }
 }
}