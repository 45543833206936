$font-size-general: 10px;
$font-family-general: 'Azo';
$font-family-uber: 'AzoUber';
$font-deco: 'Lato';
$line-height: 1;

$main-color: #ffcc07;
$dark-color: #21356A;
$light-color: #ffffff;
$lato-color: #F7F7F7;
$focus-input: #FCFDCB;
$form-button-color: #e5007e;
$button-color: #d22d7d;
$header-button-bg: #EE84B3;
$gradient-bg: linear-gradient(180deg, rgba(255, 204, 7, 1) 0%, rgba(229, 193, 212, 1) 50%, rgba(229, 0, 126, 1) 100%);
$gradient-bg-footer: linear-gradient(180deg, rgba(229,0,126,1) 100%, rgba(229,0,126,1) 100%);
$transition: all .3s linear;
$b-radius: 38px;
