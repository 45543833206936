a, button {
 cursor: pointer;
 display: inline-block;
 text-decoration: none;
 margin: 0;
 outline: none;
 transition: $transition;
 text-transform: uppercase;
 &.header-link {
  border-radius: $b-radius;
  padding: 1.3rem 3rem 1rem 3.1rem;
  color: $light-color;
  font-size: 2rem;
  font-weight: 500;
  background-color: $header-button-bg;

  &:hover {
   color: $header-button-bg;
   background-color: $light-color;
  }
 }
 &.gen-link {
  max-width: 250px;
  width: 100%;
 }
 &.popup-link {
  align-items: center;
  border: 6px solid $light-color;
  border-radius: 68px;
  background-color: $form-button-color;
  color: $light-color;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: relative;
  font-size: 3.8rem;
  margin: 0 auto;
  padding-top: .6rem;
  font-weight: 900;
  max-width: 375px;
  height: 73px;
  width: 100%;
  z-index: 101;
  &:hover {
   border-color: $form-button-color;
   background-color: $light-color;
   color: $form-button-color;
  }
 }
 &.footer-link {
  color: $lato-color;
  font-family: $font-deco;
  font-size: 1.6rem;
  text-decoration: underline;
  text-transform: capitalize;
  font-weight: 400;
  white-space: nowrap;
  &:hover {
   color: $main-color;
  }
 }
}

@media all and (max-width: 500px) {
 a, button {
  &.header-link {
   border-radius: 0;
   text-decoration: underline;
   text-align: center;
   width: 100%;
   font-size: 1.7rem;
  }
 }
}