.slider-winner-wrapper {
    max-width: 780px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    width: 100%;

    .slider-winner-list {
        align-items: center;
        display: flex;
        margin: 0;
        padding: 0;
        transition: all .6s ease-in-out;

        .slider-winner-item {
            display: block;
            opacity: 1;
            padding: 10px;
            position: relative;
            transition: $transition;

            &.is-active {
                opacity: 0;
            }
        }

        .winner-list {
            min-height: 135px;
        }

        &[data-count] {
            width: calc(100% * var(--data-count));

            .slider-winner-item {
                margin: 20px;
                width: calc((100% / var(--data-count)) - 40px);
            }
        }
    }

    .prev,
    .next {
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        height: 85px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 75px;
    }

    .prev {
        background-image: url('../images/prev.png');
        left: 2px;
    }

    .next {
        background-image: url('../images/next.png');
        right: 2px;
    }
}
