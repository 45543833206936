.main-page {
 .header {
  position: absolute;
  top: 3.3rem;
  right: 2.8rem;
  z-index: 10;
 }
 .game {
  &-hero {
   background: rgba(238, 132, 190, .5);
   position: relative;
   &-logo {
    align-items: center;
    justify-content: center;
    padding-top: 3rem;
    margin: 0 auto;
    display: flex;
    picture {
     margin: 0 auto;
     max-width: 300px;
    }
   }
   &-content {
    margin: 14rem auto 0;
    text-align: center;
   }
   &-buttons {
    margin-top: 2.7rem;
    padding-bottom: 9rem;
    .button-list {
     align-items: center;
     gap: 3rem;
     justify-content: center;
     flex-wrap: wrap;
     width: 100%;
     li {
      max-width: 270px;
      width: 100%;
      text-align: center;
      .gen-link {
       &:hover {
        .hover {
         &-on {
          display: none;
         }
         &-off {
          display: block;
         }
        }
       }
      }
      .hover {
       &-on {
        display: block;
       }
       &-off {
        display: none;
       }
      }
     }
    }
   }
   &-absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 8;
    width: 100%;
   }
   .video-container {
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    video {
     width: 100%;
     position: relative;
     height: 900px;
     object-fit: cover;
     }
   }
  }
  &-form {
   background-color: $main-color;
   padding: 10rem 0;
   .form {
    position: relative;
    z-index: 3;
    &-inputs {
     position: relative;
     z-index: 3;
    }
    &-input {
     position: relative;
     display: flex;
     input {
      border: 1px solid $form-button-color;
      background-color: $light-color;
      font-family: $font-deco;
      border-radius: ($b-radius + 30px);
      display: block;
      height: 80px;
      font-size: 2.5rem;
      color: $button-color;
      font-weight: 400;
      padding-left: 3rem;
      max-width: 330px;
      transition: $transition;
      width: 100%;
      &:focus {
       transform: scale(1.1);
       background-color: $focus-input;
       border: none;
       padding: 0;
       text-align: center;
       &::placeholder {
        font-size: 0;
       }
      }
      &::placeholder {
       font-size: 2.5rem;
       color: $dark-color;
       font-weight: 400;
      }
     }
     &-margin {
      margin-top: 5rem;
     }
     &-message {
      max-width: 380px;
      width: 100%;
     }
    }
    &-send {
     align-items: center;
     margin-top: 7rem;
     position: relative;
     z-index: 3;
    }
    &-button {
     width: 45%;
     .button-send {
      border: 6px solid ;
      border-radius: ($b-radius + 30px);
      background-color: $form-button-color;
      color: $light-color;
      cursor: pointer;
      font-size: 3.8rem;
      font-weight: 900;
      text-transform: uppercase;
      font-family: $font-family-general;
      position: relative;
      transition: $transition;
      max-width: 425px;
      padding-top: .6rem;
      height: 90px;
      width: 100%;
      z-index: 20;
      &:hover {
       border-color: $form-button-color;
       background-color: $main-color;
       color: $form-button-color;
      }
     }
    }
    &-checkbox {
     width: 55%;
     &-regulamin {
      margin-bottom: 2.3rem;
     }
     label {
      align-items: center;
      display: flex;
      p,a {
       font-size: 2.2rem;
       line-height: 1.2;
       color: $dark-color;
       font-family: $font-deco;
      }
      a {
       text-decoration: underline;
       text-transform: capitalize;
       &:hover {
        opacity: .6;
       }
      }
      input {
       appearance: none;
       background-color: $light-color;
       cursor: pointer;
       width: 40px;
       border-radius: 50%;
       transition: $transition;
       margin-right: 1.5rem;
       height: 40px;
       flex: 0 0 auto;
       position: relative;
       &:checked {
        &::before {
         content: "";
         background-color: $form-button-color;
         position: absolute;
         top: 8px;
         left: 8px;
         border-radius: 50%;
         width: 24px;
         height: 24px;
        }
       }
      }
     }
    }
    .images-position {
     position: absolute;
     bottom: 0px;
     right: 250px;
     max-width: 380px;
     z-index: 1;
    }
   }
  }
  &-price {
   background: $gradient-bg;
   padding: 5rem 0 0 0;
   &-content, &-winners {
    text-align: center;
   }

   &-content {
    .prices {
     align-items: flex-end;
     display: flex;
     flex-wrap: wrap;
     position: relative;
     top: -5.5rem;
     gap: 2rem;
     li {
      &:first-child {
       picture {
        max-width: 450px;
       }
      }
      &:nth-child(2) {
       picture {
        max-width: 780px;
       }
      }
     }
    }
   }

   &-winners {
    position: relative;
    top: -7rem;
    .winner-list {
     margin: 2.6rem;
     li {
      margin: 1rem 0;
      p {
       color: $focus-input;
      }
     }
    }
    .remember-info {
     color: $focus-input;
     font-weight: 700;
    }
    picture {
     max-width: 230px;
     margin: 0 auto 2rem;
    }
   }
  }
 }
 .whatisthis {
  margin-top: 3rem;
  .show-tab {
   align-items: center;
   font-family: $font-deco;
   color: $dark-color;
   text-transform: none;
   font-size: 2rem;
   display: flex;
   margin-left: 2rem;
   img {
    margin-right: 1rem;
   }
  }
  &-content {
   background-color: $light-color;
   border: 4px solid $form-button-color;
   border-radius: ($b-radius + 20px);
   text-align: center;
   padding: 4rem;
   max-width: 1000px;
   transition: all .6s ease-in-out;
   width: 90%;
   left: 50%;
   transform: translateX(-50%) translateY(50%);
   position: fixed;
   bottom: 1000%;
   z-index: 100;
   &.show {
    bottom: 50%;
   }
   h3 {
    line-height: 1.2;
   }
   .card {
    align-items: center;
    gap: 3.7rem;
    padding-bottom: 6rem;
    picture {
     max-width: 440px;
    }
   }
  }
 }
 .close, .hidden-btn {
  border-radius: 50%;
  cursor: pointer;
  background-color: $form-button-color;
  position: absolute;
  display: block;
  top: 7%;
  right: 5%;
  height: 70px;
  width: 70px;
  z-index: 2;
  &::before, &::after {
   content: "";
   background-color: $light-color;
   width: 50px;
   height: 4px;
   position: absolute;
   right: 10px;
   top: 33px;
   z-index:1;
  } 
  &::before {
   transform: rotate(45deg);
  }
  &::after {
   transform: rotate(-45deg);
  }
 }
 
 .desktop-show {
  display: block;
 }
 .mobile-show {
  display: none;
 }
 .notification-success, .invalid-feedback, .notification-error {
  color: $light-color;
  border-radius: ($b-radius + 30px);
  padding: 1rem;
  font-size: 1.5rem;
  position: relative;
  text-align: center;
  width: 100%;
 }
 .notification-error {
    margin-bottom: 1rem;
 }
 .invalid-feedback, .notification-error{
  background-color: red;
  margin-top: 1rem;
  max-width: 300px;
  &-checkbox {
   max-width: 430px;
  }
 }
 .notification-success {
  background-color: #34CCC0;
  margin-bottom: 1rem;
  max-width: 600px;
 }
 .notification-mess {
  background-color: white;
  background-color: $light-color;
  border: 4px solid $form-button-color;
  border-radius: ($b-radius + 20px);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  position: fixed;
  padding: 4rem;
  left: 50%;
  top: 50%;
  text-align: center;
  transition: all .6s ease-in-out;
  transform: translate(-50%, -50%);
  max-width: 1000px;
  width: 90%;
  z-index: 100;
  p {
    margin: 0 auto;
    max-width: 500px;
  }
 }
 .hidden {
  left: -100%;
  opacity: 0;
  pointer-events: none; 
 }
}
@media all and (max-width: 1600px) {
 .main-page {
  .game {
   &-hero {
    height: 758px;
    .video-container {
     top: -70px;
    }
    &-content {
     margin-top: 5rem;
    }
   }
  }
 }
}
@media all and (max-width: 1230px) {
 .main-page {
  .game {
   &-price {
    &-content {
     .prices {
      justify-content: center;
      position: relative;
      top: 0;
      gap: 0;
      padding: 4rem 0;
      li {
       &:nth-child(2) {
        picture {
         max-width: 550px;
        }
       }
      }
     }
    }
    &-winners {
      top: -3rem;
    }
   }
   &-form {
    .form {
     &-input {
      input {
       max-width: 300px;
      }
     }
    }
   }
  }
 }
}
@media all and (max-width: 1024px) {
 .main-page {
  .game {
   &-hero {
    &-content {
     margin-top: 5rem;
    }
   }
  }
 }
}
@media all and (max-width: 1320px) {
 .main-page {
  .game {
   &-form {
    .form {
     .images-position {
      position: absolute;
      bottom: 0px;
      right: 0px;
      max-width: 350px;
      z-index: 1;
     }
    }
   }
  }
 }
}
@media all and (max-width: 900px) {
 .main-page {
  .game {
   &-form {
    .form {
     &-send {
      flex-direction: column;
      row-gap: 3rem;
     }
     &-button {
      text-align: center;
     }
     &-checkbox, &-button {
      width: 100%;
     }
    }
   }
  }
 }
}
@media all and (max-width: 768px) {
 .main-page {
  .game {
   &-price {
    &-winners {
    padding-bottom: 0rem;
    }
   }
   &-form {
    overflow: hidden;
    padding:10rem 0 4rem;
    .form-input-margin {
      margin-top: 1rem;
    }
    .form {
     &-inputs {
      .whatisthis {
       .show-tab {
        justify-content: flex-end;
       }
      }
     }
     .form-input-message {
      max-width: 370px;
     }
     &-input {
      align-items: flex-end;
      flex-direction: column;
      right: -15px;
      gap: 1rem;
      input {
       max-width: none;
       border-radius: 38px 0 0 38px;
       border-right-color: $light-color;
       &:focus {
        transform: none;
       }
      }
     }
     .images-position {
      top: -25px;
      left: 0;
      bottom: inherit;
      max-width: 300px;
      right: inherit;
     }
    }
   }
  }
  .whatisthis {
   &-content {
    h3 {
     text-align: left;
     width: 90%;
    }
    .card {
     margin-top: 2rem;
     padding-bottom: 3rem;
     flex-direction: column-reverse;
     gap: 1rem;
    }
   }
  }
  .desktop-show {
    display: none;
  }
  .mobile-show {
    display: block;
    margin: 0;
    position: relative;
    left: -150px;
  }
  .close, .hidden-btn {
    top: 7%;
    right: 5%;
    height: 35px;
    width: 35px;
    &::before, &::after {
     width: 25px;
     height: 3px;
     right: 5px;
     top: 16px;
     z-index:1;
    }
   }
 }
}
@media all and (max-width: 599px) {
 .main-page {
  .game {
   &-form {
    padding:23rem 0 10rem;
   }
   &-hero {
    &-absolute {
     top: 70%;
    }
   }
  }
 }
}
@media all and (max-width: 500px) {
 .main-page {
  .header {
   position: relative;
   top: 0;
   left: 0;
   width: 100%;
  }
  .game {
   &-hero {
    &-content {
     h1 {
      margin-bottom: 1rem;
     }
    }
   }
   &-form {
    .form {
     &-send {
      margin-top: 3rem;
      label {
       input {
        margin-right: .7rem;
        height: 30px;
        width: 30px;
        &:checked {
         &:before {
          top: 6px;
          left: 6px;
          width: 18px;
          height: 18px;
         }
        }
       }
       p,a {
        font-size: 1.6rem;
       }
      }
     }
     &-send {
      .form-button {
       .button-send {
        font-size: 2.5rem;
        height: 70px;
       }
      }
     }
    }
   }
  }
  .whatisthis {
   &-content {
    padding: 2.5rem;
    .card {
     padding-bottom: 3rem;
     flex-direction: column-reverse;
     gap: 1rem;
    }
   }
  }
 }
}
@media all and (max-width: 426px) {
 .main-page {
  .game {
   &-hero {
    height: 500px;
    &-content {
     margin-top: 11rem;
     position: relative;
     top: -3rem;
    }
    .video-container {
      top: -52px;
      video {
        height: 600px;
      }
    }
    &-logo {
      position: relative;
      top: 20px;
     picture {
      max-width: 170px;
     }
    }
    &-buttons {
     padding: 0;
     margin-top: 0;
    }
   }
   &-form {
    padding: 28rem 0 0 0;
    .form {
     .form-input-message {
      max-width: 250px;
     }
     &-input {
      input, input::placeholder {
       font-size: 1.8rem;
      }
      input {
        &:focus {
          padding-left: 3rem;
          text-align: left;
        }
      }
     }
     &-send {
      .form-checkbox {
        .form-checkbox-regulamin {
          label {
            input {
              top: -5px;
            }
          }
        }
        .form-checkbox-policy {
          label {
            input {
              top: -25px;
            }
          }
        }
      }
     }
     .images-position {
      top: 25px;
      left: -20px;
      bottom: inherit;
      right: inherit;
      max-width: 230px;
     }
    }
   }
  }
  .notification-success {
   left: 0;
   margin: 0 auto 1rem;
  }
  .whatisthis {
    &-content {
      h3 {
        font-size: 1.5rem;
      }
    }
  }
  .mobile-show {
    left: -80px;
  }
  .notification-mess {
    gap: 1.5rem;
    padding: 2.5rem;
   }
 }
}
@media all and (max-width: 375px) {
 .main-page {
  .invalid-feedback {
   text-align: left;
   left: -15px;
   &-checkbox {
    text-align: center;
    left: 0;
   }
  }
  .hidden-btn {
    right: 3%;
    top: 8%;
    width: 29px;
    height: 29px;
    &::before, &::after {
      width: 23px;
      right: 3px;
      top: 13px;
    }
  }
 }
}
